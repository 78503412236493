import PropTypes from "prop-types";

import { Icon } from "../../../../components/BaseComponents/Icon";

export const CouponDescriptionScissorIcon = ({ style }) => (
	<Icon fill="none" viewBox="0 0 15 15" css={style}>
		<path
			fill="currentColor"
			d="M9.5 2.9464c0 .5357.1 1.0179.35 1.3929L8.1 5.9464l-3.5-3.75C3.35.9107 1.45.643 0 1.6071L6.4 7.5 0 13.3929c1.45.9642 3.35.6964 4.55-.5893l3.5-3.75 1.75 1.6071c-.2.375-.3.8572-.3 1.3929C9.5 13.6607 10.75 15 12.25 15S15 13.6607 15 12.0536c0-1.6072-1.25-2.9465-2.75-2.9465-.35 0-.7.0536-1.05.2143L9.5 7.5l1.7-1.8214c.3.1607.65.2143 1.05.2143 1.5 0 2.75-1.3393 2.75-2.9465C15 1.3393 13.75 0 12.25 0S9.5 1.3393 9.5 2.9464Zm4.5 9.1072c0 1.0178-.8 1.875-1.75 1.875s-1.75-.8572-1.75-1.875c0-1.0179.8-1.875 1.75-1.875s1.75.8571 1.75 1.875Zm0-9.1072c0 1.0179-.8 1.875-1.75 1.875s-1.75-.8571-1.75-1.875c0-1.0178.8-1.875 1.75-1.875S14 1.9286 14 2.9464Z"
		/>
	</Icon>
);

CouponDescriptionScissorIcon.propTypes = {
	style: PropTypes.object.isRequired,
};
