import { PageContext } from "../../components/CompanyList/PageContext";
import { AppProviders } from "../../components/UIComponents/AppProviders";
import { BaseCompanyList } from "../../components/UIComponents/BaseCompanyList";
import { BusinessFormationCompany } from "./verticals/BusinessFormationCompany";
import styles from "./verticals/pageLayoutStyles";

export const App = () => (
	<PageContext.Provider
		value={{
			pageStyles: styles,
			companyComponent: BusinessFormationCompany,
		}}
	>
		<AppProviders>
			<BaseCompanyList />
		</AppProviders>
	</PageContext.Provider>
);
