import { useContext } from "react";
import { getCouponDescription } from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { CouponDescriptionScissorIcon } from "../../client/assets/icons/common/CouponDescriptionScissorIcon";
import { CompanyContext } from "../CompanyList/CompanyContext";

const BaseCouponDescription = ({ style }) => {
	const { company } = useContext(CompanyContext);
	const couponDescription = useSelector((state) =>
		getCouponDescription(state, { company }),
	);
	if (!couponDescription) return null;

	return (
		<div css={style.wrapper}>
			<div css={style.icon.wrapper}>
				<CouponDescriptionScissorIcon style={style.icon.content} />
			</div>
			<span css={style.content}>{couponDescription}</span>
		</div>
	);
};

export default BaseCouponDescription;

BaseCouponDescription.propTypes = {
	style: PropTypes.object.isRequired,
};
